export const MIN_TEMP = 10;
export const MAX_TEMP = 28;

export const MAX_AREA = 500000; //m2

export const FORM_SUBTYPES_WITH_AREA_LIMITS = [
  'Activity_Treatment_MechanicalPlantTerrestrial',
  'Activity_Treatment_MechanicalPlantAquatic',
  'Activity_Observation_PlantTerrestrial',
  'Activity_Treatment_ChemicalPlantTerrestrial',
  'Activity_Treatment_ChemicalPlantAquatic',
  'Activity_Observation_PlantAquatic',
  'Activity_Biocontrol_Release',
  'Activity_Monitoring_ChemicalTerrestrialAquaticPlant',
  'Activity_Monitoring_MechanicalTerrestrialAquaticPlant',
  'Activity_Monitoring_BiocontrolRelease_TerrestrialPlant',
  'Activity_Monitoring_BiocontrolDispersal_TerrestrialPlant',
  'Activity_AnimalActivity_AnimalTerrestrial',
  'Activity_AnimalActivity_AnimalAquatic',
  'Activity_Transect_FireMonitoring',
  'Activity_Transect_Vegetation',
  'Activity_Transect_BiocontrolEfficacy',
  'Activity_Biocontrol_Collection'
];

export const VALID_GEOMETRY_TYPES = [
  'GeometryCollection',
  'MultiPolygon',
  'MultiLineString',
  'MultiPoint',
  'Polygon',
  'LineString',
  'Point'
];
